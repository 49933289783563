import React from "react";
import "./slider-item.scss";
import cn from "classnames";

const SliderItem = ({ children, className, onRef }) => (
	<div ref={ref => onRef(ref)} className={cn("slider_item", className)}>
		{children}
	</div>
);

export default SliderItem;
