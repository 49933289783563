import React, { useState, useMemo, useEffect } from "react";
import { YMaps, Map, Placemark, GeolocationControl } from "react-yandex-maps";
import smoothscroll from "smoothscroll-polyfill";
import throttle from "lodash/throttle";

import "./map-with-slider.scss";

import { Slider, SliderItem } from "@components/slider";
import { Location } from "./components";
import shadow from "@img/shadow.png";

const MapWithSlider = ({ locations, iconLocations }) => {
	const [center, setCenter] = useState([55.812031, 49.121905]);
	const [slider, setSlider] = useState(undefined);
	const [arrowLeft, setArrowLeft] = useState(false);
	const [arrowRight, setArrowRight] = useState(false);

	const handleArrowsVisibility = throttle(
		() => {
			let leftArrowIsShown = false;
			let rightArrowIsShown = false;

			// Есть куда скроллить влево
			if (slider.scrollLeft > 0) {
				leftArrowIsShown = true;
			}

			// Есть куда скроллить вправо
			if (slider.scrollWidth - (slider.scrollLeft + slider.clientWidth) > 0) {
				rightArrowIsShown = true;
			}

			if (leftArrowIsShown !== arrowLeft || rightArrowIsShown !== arrowRight) {
				setArrowLeft(leftArrowIsShown);
				setArrowRight(rightArrowIsShown);
			}
		},
		1,
		{ trailing: true, leading: true }
	);

	useEffect(() => {
		smoothscroll.polyfill();
		if (slider) {
			handleArrowsVisibility();
			slider.addEventListener("scroll", handleArrowsVisibility);
		}
		return () => {
			slider && slider.removeEventListener("scroll", handleArrowsVisibility);
		};
	});

	const mapState = useMemo(
		() => ({
			center,
			zoom: 12,
			controls: ["zoomControl", "fullscreenControl"]
		}),
		[center]
	);

	const arr = [];
	let border;

	const nextProperty = () => {
		slider.scrollBy({
			// Ширина одного блока
			left: 440,
			behavior: "smooth"
		});
	};
	const prevProperty = () => {
		slider.scrollBy({
			// Ширина одного блока
			left: -440,
			behavior: "smooth"
		});
	};

	return (
		<section className="map-with-slider">
			<div className="map-with-slider__ymaps">
				<YMaps>
					<Map
						className="map-with-slider__ymaps__map"
						state={mapState}
						modules={["control.ZoomControl", "control.FullscreenControl"]}
						instanceRef={ref => {
							ref && ref.behaviors.disable("scrollZoom");
						}}
					>
						<GeolocationControl options={{ float: "left" }} />
						{locations &&
							locations.map(
								({ id, index, title, workTime, placeMark, phone, image }) => (
									<Placemark
										key={id}
										modules={["geoObject.addon.balloon"]}
										geometry={placeMark}
										properties={{
											balloonContentHeader: title,
											balloonContentBody: `
												<img src=${image} style="max-height: 240px; display: block; padding: 12px 0;"/>
												${phone && phone.join(",<br/>")}
												`,
											balloonContentFooter: workTime && workTime.join(",<br/>")
										}}
										onClick={() => {
											const a = arr[index].getBoundingClientRect().x;
											const b = border.getBoundingClientRect().x;
											slider.scrollBy({
												left: a - b,
												behavior: "smooth"
											});
										}}
										options={{
											iconLayout: "default#image",
											iconImageHref: iconLocations,
											iconImageSize: [32, 32],
											iconImageOffset: [-16, -8],
											iconShadow: true,
											iconShadowImageHref: shadow,
											iconShadowImageSize: [42, 42],
											iconShadowImageOffset: [-16, -8],
											hideIconOnBalloonOpen: false
										}}
									/>
								)
							)}
					</Map>
				</YMaps>
			</div>

			<div className="slider-wrapper hidden-xs">
				{locations && !!locations.length && (
					<Slider onRef={ref => setSlider(ref)}>
						{locations.map(location => (
							<SliderItem
								key={location.id}
								onRef={ref => {
									arr.push(ref);
								}}
							>
								<Location
									{...location}
									onClick={placeMark => setCenter(placeMark)}
								/>
							</SliderItem>
						))}
					</Slider>
				)}

				<div className="grid-container">
					<i
						ref={ref => {
							border = ref;
						}}
					/>
					<div className="row">
						<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
							<section className="slider-controls">
								<button onClick={prevProperty} disabled={!arrowLeft}>
									←
								</button>
								<button onClick={nextProperty} disabled={!arrowRight}>
									→
								</button>
							</section>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default MapWithSlider;
