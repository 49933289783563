import React from "react";
import "./location-info-block.scss";
import PropTypes from "prop-types";

const LocationInfoBlock = ({ title, content, type }) => {
	return (
		<div className="location-info-block">
			<div className="location-info-block__title">{title}</div>
			<ul className="location-info-block__content">
				{content.map((item, index) => {
					let view = null;
					if (type === "phone") {
						view = (
							<li key={index}>
								<a rel="noopener noreferrer" href={`tel:${item}`}>
									{item}
								</a>
							</li>
						);
					} else {
						view = <li key={index}>{item}</li>;
					}
					return view;
				})}
			</ul>
		</div>
	);
};

LocationInfoBlock.propTypes = {
	title: PropTypes.string,
	type: PropTypes.string,
	content: PropTypes.arrayOf(PropTypes.string)
};

LocationInfoBlock.defaultProps = {
	title: "",
	content: [],
	type: null
};

export default LocationInfoBlock;
