import React from "react";
import "./location.scss";
import LocationInfoBlock from "../location-info-block/location-info-block";

const Location = ({
	title,
	phone,
	fax,
	workTime,
	placeMark,
	onClick,
	image
}) => {
	return (
		<div className="location">
			<h4
				onClick={() => {
					onClick(placeMark);
				}}
			>
				{title}
			</h4>
			{phone && (
				<LocationInfoBlock title="Телефоны:" content={phone} type="phone" />
			)}
			{fax && <LocationInfoBlock title="Факс:" content={fax} type="phone" />}
			{workTime && (
				<LocationInfoBlock title="Часы работы:" content={workTime} />
			)}
		</div>
	);
};

Location.propTypes = {};

Location.defaultProps = {
	onClick: () => {}
};

export default Location;
