import React from "react";
import "./slider.scss";

const Slider = ({ children, onRef }) => (
	<div className="full-width slider">
		<div className="slider__wrapper" ref={ref => onRef(ref)}>
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<ul className="slider__wrapper__list">{children}</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default Slider;
