import React from "react";
import "./offices.scss";

import MapWithSlider from "@components/map-with-slider";
import { graphql, StaticQuery } from "gatsby";

import { transformLocations } from "@utils/utils";

const Offices = ({ data }) => {
	const {
		markdownRemark: { frontmatter: { image, locations } = {} } = {}
	} = data;
	return (
		<section className="full-width offices">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2 className="offices__header">Пункты по работе с населением</h2>
					</div>
				</div>
			</div>

			<MapWithSlider
				locations={transformLocations(locations)}
				iconLocations={image}
			/>
		</section>
	);
};

export default props => (
	<StaticQuery
		query={graphql`
			query {
				markdownRemark(frontmatter: { path: { eq: "/contacts/offices" } }) {
					frontmatter {
						path
						locations {
							title
							image
							placeMark {
								x
								y
							}
							phone {
								number
							}
							workTime {
								time
							}
						}
						image
					}
				}
			}
		`}
		render={data => <Offices data={data} {...props} />}
	/>
);
